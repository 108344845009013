import React from "react";
import TextField from "../../components/core/TextField";
import PasswordTextField from "../../components/core/PasswordTextField";
import { LoadingButton } from "@mui/lab";
import BackendError from "../../components/core/BackendError";
import { ForgotPassword, Container } from "./styles";
import { Box, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";

const LoginForm = ({ handleFormSubmit, errorMsg, loading }) => {
  const initialValues = {
    email: "",
    password: "",
  };

  const ValidationSchema = yup.object().shape({
    email: yup.string().required(`Email is required!`).email("Invalid email"),
    password: yup.string().required(`Password is required!`),
  });

  return (
    <React.Fragment>
      <Container elevation={0}>
        <Formik
          initialValues={{ ...initialValues }}
          validationSchema={ValidationSchema}
          onSubmit={handleFormSubmit}
        >
          {({
            errors,
            error,
            touched,
            values,
            handleChange,
            handleBlur,
            onBlur,
            isValid,
            resetForm,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Typography variant="h1" sx={{ mb: { xs: "18px", md: "30px" } }}>
                Sign In
              </Typography>
              <TextField
                label={"Email"}
                name="email"
                placeholder="Enter your email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                sx={{ mb: "16px" }}
              />

              <PasswordTextField
                label={"Password"}
                name="password"
                placeholder="Enter your password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                sx={{ mb: "10px" }}
              />

              <ForgotPassword to="/forgot-password">
                Forgot password?
              </ForgotPassword>

              <Box item mb={ "16px" } mt={ "16px" }>
                <BackendError text={isValid && errorMsg} />
              </Box>

              <LoadingButton
                type="submit"
                height="44px"
                sx={{
                  width: "100%",
                }}
                variant="contained"
                disabled={ loading }
                loading={ loading }
              >
                SIGN IN
              </LoadingButton>
            </form>
          )}
        </Formik>
      </Container>
    </React.Fragment>
  );
};

export default LoginForm;
