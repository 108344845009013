import { styled, Button } from "@mui/material";
import PropTypes from "prop-types";
import { gradients } from "../../themes/colors";

const StyledButton = styled(Button)(({ theme, width, height }) => ({
  "&.MuiButton-root": {
    height: height,
    width: width,
    borderRadius: "16px",
    fontSize: "14px",
    fontWeight: "bold",
    textTransform: "none",
  },
  "&.MuiButton-contained": {
    height: height,
    width: width,
    color: "white",
    background: gradients.primary,
    boxShadow: "none",
  },
  "&.MuiButton-outlined": {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    fontSize: "14px",
    fontWeight: "500",
  },
  "&.Mui-disabled": {
    color: "white",
    background: '#3F44511F',
  },
}));

StyledButton.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default StyledButton;
