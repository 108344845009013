import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { persistStore } from "redux-persist";
import thunk from "redux-thunk";
import authReducer from "./slices/authSlice";

const persistConfig = {
  key: "liima",
  storage,
};

const reducers = combineReducers({
  auth: authReducer, 
});

const persistedReducer = persistReducer(persistConfig, reducers);
const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});
export const persistor = persistStore(store);
export default store;
