import { lazy } from "react";
const Dashboard = lazy(() => import("../pages/private/Dashboard"));
const Profile = lazy(() => import("../pages/private/Profile"));
const Payments = lazy(() => import("../pages/private/Payments"));
const EditProfile = lazy(() => import("../pages/private/EditProfile"));

const privateRoutes = [
  {
    path: "/dashboard",
    component: <Dashboard />,
    routeName: "Dashboard",
  },
  {
    path: "/profile",
    component: <Profile />,
    routeName: "Profile",
  },
  {
    path: "/payments",
    component: <Payments />,
    routeName: "Payments",
  },
  {
    path: "/edit-profile",
    component: <EditProfile />,
    routeName: "Edit profile",
  },
];
export default privateRoutes;
