import PropTypes from "prop-types";
import { styled, Alert } from "@mui/material";

const MyAlert = styled(Alert)(({ theme, is_error }) => ({
  display: is_error ? "flex" : "none",
  justifyContent: "center",
  marginTop: "5px",
}));

const BackendError = ({ text }) => {
  return (
    <MyAlert is_error={text} severity='error'>
      {text}
    </MyAlert>
  );
};

BackendError.propTypes = {
  text: PropTypes.string,
};

export default BackendError;
