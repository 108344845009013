import * as React from "react";
import Appbar from "./AppbarAuth";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LiimaCards from "../../../assets/images/LiimaCardGroup.png";
import LiimaButton from "../../core/Button";
import { useNavigate } from "react-router-dom";

export default function SignIn(props) {
  const navigate = useNavigate();

  return (
    <Box
      component="main"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
        maxHeight: "100vh",
        width: "100%",
        padding: 0,
      }}
    >
      <Appbar />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
          width: "100%",
          maxWidth: "1062px",
          height: "100%",
          mt: { xs: "36px", md: 0 },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            maxWidth: "494px",
            px: { xs: "34px", md: 0 },
          }}
        >
          <Box sx={{ width: "100%" }}>
            <img
              alt="Liima Cards - Front and Back"
              src={LiimaCards}
              width="100%"
              height="auto"
            />
          </Box>

          <Typography
            align="center"
            sx={{
              fontSize: { xs: "28px", md: "46px" },
              lineHeight: { xs: "32px", md: "56px" },
              fontWeight: 500,
              mt: { xs: "15px", md: "20px" },
            }}
          >
            Digital business cards <br />
            <span style={{ fontWeight: 200 }}>for modern networking.</span>
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "10px", md: "15px" },
              lineHeight: { xs: "16px", md: "24px" },
              letterSpacing: { xs: "0.3px", md: "1.2px" },
              fontWeight: 200,
              mt: "4px",
              color: "#585858",
            }}
          >
            Liima smart card helps you build & nurture new connections.
          </Typography>
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              width: "100%",
              px: "20px",
              my: "18px",
            }}
          >
            <LiimaButton
              variant="outlined"
              height={"37px"}
              fullWidth
              onClick={() => navigate("/signup")}
            >
              GET YOUR CARD
            </LiimaButton>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: { xs: "100%", md: "auto" },
            maxWidth: "505px",
            mt: { xs: "4px", md: 0 },
          }}
        >
          {props.children}
        </Box>
      </Box>
    </Box>
  );
}
