import React, { useState } from "react";
import AuthLayout from "../../components/layouts/AuthLayout";
import LoginForm from "../../forms/LoginForm";
import store from "../../store";
import { login } from "../../services/authServices";
import { isString } from "lodash";

const Login = () => {
  const { dispatch } = store;
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (data) => {
    setError(null);
    setLoading(true);
    dispatch(login(data))
      .unwrap()
      .then(() => {
        setError(null);
        setLoading(false);
      })
      .catch((error) => {
        setError(
          error?.response?.data?.message ||
            (isString(error)
              ? error
              : "Something went wrong, please try again.")
        );
        setLoading(false);
      });
  };

  return (
    <AuthLayout>
      <LoginForm
        errorMsg={error}
        handleFormSubmit={handleSubmit}
        loading={loading}
      />
    </AuthLayout>
  );
};

export default Login;
