import { createSlice } from "@reduxjs/toolkit";
import { login, refreshToken, updateUser } from "../../services/authServices";

const initialState = {
  currentUser: null,
  accessToken: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: () => {
      return {
        currentUser: null,
        accessToken: null,
      };
    },
    updateDefaultUser: (state, action) => {
      const updatedFields = action.payload;
      
      state.currentUser = {
        ...state.currentUser,
        ...updatedFields,
      };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, { payload }) => {
      return {
        ...state,
        currentUser: payload.user,
        accessToken: payload.token,
      };
    });
    builder.addCase(login.rejected, (state, { payload }) => {
      return {
        ...state,
        currentUser: null,
        accessToken: null,
      };
    });

    builder.addCase(refreshToken.fulfilled, (state, { payload }) => {
      return {
        ...state,
        accessToken: payload,
      };
    });
    builder.addCase(refreshToken.rejected, () => {});

    builder.addCase(updateUser.fulfilled, (state, { payload }) => {
      return {
        ...state,
        currentUser: payload,
      };
    });
  },
});

export const { logout, updateDefaultUser } = authSlice.actions;

export const updateCurrentUser = (userData) => {
  return (dispatch) => {
    dispatch(updateDefaultUser(userData));
  };
};

export default authSlice.reducer;
