import { createTheme } from "@mui/material/styles";
import typography from "./typography";
import { colors } from "@mui/material";

const theme = createTheme({
  palette: {
    background: {
      default: "#FFFFFF",
      paper: colors.common.white,
    },
    primary: {
      main: "#6149CD",
      secondary: "#6B70DF",
    },
    error: {
      main: "#FF7A7A",
    },
    text: {
      primary: "#3F4451",
      secondary: "#7A86A1",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        "root": {
          "height": "44px",
          "width": "100%",
          "borderRadius": "16px",
          "fontSize": "14px",
          "fontWeight": "bold",
          "textTransform": "none",
          "&.Mui-disabled": {
            "color": "white",
            "background": "#3F44511F",
            "borderRadius": "16px",
          }
        },
        "disableElevation": true,
        "contained": {
          "background": "transparent linear-gradient(268deg, #8C72FF 0%, #C057EF 47%, #F0749B 100%) 0% 0% no-repeat padding-box",
          "color": "white",
          "boxShadow": "none",
          "&.Mui-disabled": {
            "background": "#E0E0E0 !important"
          }
        },
        "outlined": {
          "color": "#6149CD",
          "borderColor": "#6149CD",
          "fontSize": "14px",
          "fontWeight": "500"
        }
      }
      
    },
  },
  typography,
});

export default theme;
