import axios from "axios";
import { refreshToken } from "./authServices";
import store from "../store";
import { logout } from "../store/slices/authSlice";

const API = axios.create();

API.interceptors.request.use(
  (config) => {
    const token = store?.getState()?.auth?.accessToken;
    config.baseURL = "/api/v1";
    config.headers.authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      return await store
        .dispatch(refreshToken())
        .unwrap()
        .then(() => {
          return API(error.config);
        })
        .catch(() => {
          store.dispatch(logout());
        });
    }
    return Promise.reject(error);
  }
);

export default API;
