import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store, { persistor } from "./store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from '@mui/material/styles';
import theme from "./themes";
import CssBaseline from '@mui/material/CssBaseline';
import Loading from "./components/core/Loading";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={ store }>
    <PersistGate loading={ null } persistor={ persistor }>
      <React.StrictMode>
        <React.Suspense fallback={ <Loading /> }>
          <ThemeProvider theme={ theme }>
            <CssBaseline />
            <App />
          </ThemeProvider>
        </React.Suspense>
      </React.StrictMode>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();