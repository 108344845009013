import { styled, Stack, TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";

const TextBox = styled(({ ...rest }) => <Stack direction="column" {...rest} />)(
  ({ theme }) => ({
    width: "100%",
    gap: "6px",
  })
);

export const StyledTextField = styled(TextField)(
  ({ theme, height, max_width }) => ({
    ".MuiOutlinedInput-root": {
      boxSizing: "border-box",
      maxHeight: height ? `${height}px` : "44px",
      width: "100%",
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.common.white,
      maxWidth: `${max_width}px`,
      borderRadius: "16px",
      fieldset: {
        border: `.75px solid #3F44511F`,
      },

      "&.Mui-focused fieldset": {
        border: ".75px solid #3F44511F",
      },
    },

    "& .MuiInputBase-root.Mui-disabled": {
      fieldset: {
        borderColor: "#3F44511F",
      },
    },

    ".MuiOutlinedInput-root:hover": {
      fieldset: {
        borderColor: "#868DAA",
      },
    },

    input: {
      fontSize: "14px",

      "&::placeholder": {
        fontSize: "14px",
        fontWeight: "400",
        color: "#181D333E",
        opacity: "1",
      },
      height: height ? `${height}px` : "44px",
      padding: "0 16px",
    },

    "& .MuiFormHelperText-root": {
      color: "red",
      fontSize: "10px",
    },
  })
);

const MyTextField = ({ label, width, ...rest }) => {
  const textFieldProps = {
    variant: "outlined",
    fullWidth: true,
    ...rest,
  };

  return (
    <TextBox width={width}>
      <Typography sx={{ lineHeight: "22px" }}> {label}</Typography>
      <StyledTextField {...textFieldProps} />
    </TextBox>
  );
};

MyTextField.propType = {
  label: PropTypes.string,
  width: PropTypes.number,
};

export default MyTextField;
