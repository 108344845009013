import { styled, Paper } from "@mui/material";
import { NavLink } from "react-router-dom";

export const Container = styled(Paper)(({ theme }) => ({
  boxSizing: "border-box",
  borderRadius: "18px",
  backgroundColor: "white",
  boxShadow: "4px 6px 26px #0000000F",
  maxWidth: "624px",
  width: "100%",
  maxHeight: "calc(100vh   - 40px)",
  padding: "46px 36px",
  overflow: "auto",
  "::-webkit-scrollbar": { display: "none" },
  marginBottom: 0,
  [theme.breakpoints.down("sm")]: {
    padding: "18px 15px",
    width: "calc(100vw - 40px)",
    marginBottom: "30px",
  },
}));

export const ForgotPassword = styled(NavLink)(({ theme }) => ({
  fontSize: "14px",
  color: "#4E5983",
  fontWeight: "400",
}));
