import { lazy } from "react";
const SignIn = lazy(() => import("../pages/auth/Login"));
const SignUp = lazy(() => import("../pages/auth/Signup"));
const ForgotPassword = lazy(() => import("../pages/auth/ForgotPassword"));
const ResetPassword = lazy(() => import("../pages/auth/ResetPassword"));

const publicRoutes = [
  {
    path: "/login",
    component: <SignIn />,
  },
  {
    path: "/signup",
    component: <SignUp />,
  },
  {
    path: "/forgot-password",
    component: <ForgotPassword />,
  },
  {
    path: "/reset-password",
    component: <ResetPassword />,
  },
];
export default publicRoutes;
