import API from "./api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import store from "../store";
// import { toggleSession } from "../store/features/sessionSlice";

export const getPrice = async (id) => {
  try {
    const response = await API.get(`/payment/subscription/plans`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getInvoice = async (id) => {
  try {
    const response = await API.get(`/payment/invoice/list/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getProfiles = async (id) => {
  try {
    const response = await API.get(`/profile/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createProfiles = async (id, data) => {
  try {
    const response = await API.post(`/profile/${id}`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const updateProfiles = async (id, data) => {
  try {
    const response = await API.post(`/profile/update/${id}`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const deleteProfile = async (id, data) => {
  try {
    const response = await API.delete(`/profile/delete/${id}`, {
      params: data
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getViews = async (id) => {
  try {
    const response = await API.get(`/profile/profile_stats/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const signup = async (data) => {
  try {
    const response = await API.post("/user/signup", data);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const login = createAsyncThunk(
  "auth/login",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post("/auth/login", data);
      const { role } = response?.data?.data?.user;
      if (role !== "user" && role !== "employee" && role !== "review_user") {
        return rejectWithValue(`You don't have access to this system !`);
      }
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const refreshToken = createAsyncThunk(
  "auth/refreshToken",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get("/auth/refresh-token");
      return response.data.data.token;
    } catch (error) {
      if (error.response.status === 500) {
        console.log("refresh error");
        // store.dispatch(toggleSession());
      }

      return rejectWithValue(error);
    }
  }
);

export const updateUser = createAsyncThunk(
  "auth/updateUser",
  async (data, { rejectWithValue }) => {
    const id = store.getState()?.auth?.currentUser?.id;
    try {
      const response = await API.post(`/user/${id}`, data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const changePassword = async (data) => {
  const id = store.getState()?.auth?.currentUser?.id;
  try {
    const response = await API.post(`/user/${id}`, data);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const forgotPassword = async (email) => {
  try {
    const response = await API.post("/auth/forgot-password", email);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (data) => {
  try {
    const response = await API.post("/auth/password-reset", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const activateUser = async (data) => {
  try {
    const response = await API.post("/auth/activate", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const getReviewUser = async (id, data) => {
  try {
    const response = await API.get(`/user/review_user/stats/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateReviewLink = async (id, data) => {
  try {
    const response = await API.put(`/user/review_user/${id}`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};