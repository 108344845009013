import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LoginPage from "./pages/auth/Login";
import publicRoutes from "./routes/publicRoutes";
import privateRoutes from "./routes/privateRouts";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export default function App() {
  const auth = useSelector((state) => state.auth.accessToken);

  const publicRoute = (route) => {
    if (auth) {
      return;
    }
    return (
      <Route key={route.path} path={route.path} element={route.component} />
    );
  };

  const privateRoute = (route, index) => {
    if (!auth) {
      return;
    }
    return <Route key={index} path={route.path} element={route.component} />;
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        closeOnClick
        // pauseOnHover
        theme="light"
        hideProgressBar={true}
      />
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={auth ? <Navigate replace to="/profile" /> : <LoginPage />}
            />
          {publicRoutes.map((route, index) => publicRoute(route, index))}
          {privateRoutes.map((route, index) => privateRoute(route, index))}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
